<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_kol + webp()" alt="" class="im-image" />
      <!--      <img
        v-show="item.variant_id === 2279"
        :src="m_kolm + webp()"
        alt=""
        class="im-image"
      />-->
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Доп. колонна 12 нерж. тарелок</div>
          <!--          <div class="im-title" v-else-if="item.variant_id === 2279">
            Заменить 9 тарелок на медные
          </div>-->

          <div class="pt-1 pb-3 im-text" v-if="item.variants">
            <switch-select :variants="item.variants" :item="item" />
          </div>
          <div class="im-subtitle">Спирт 96% об. на 2 л/час</div>
          <!--          <div class="im-subtitle" v-else-if="item.variant_id === 2279">
            Для перегонки зерновых и фруктово-ягодных браг
          </div>-->
          <div class="im-text">
            <p>
              DomSpirt 2 в комплектации с невысокой (6 ступеней) колонной
              обеспечивает укрепление до 94%. Это позволяет получать дистилляты
              из зернового (виски) и плодово-ягодного сырья (бренди) без
              выдержки в бочках, но при этом без «самогонного» запаха.
              Классический самогон из сахара на 6 медных тарелках также по вкусу
              и аромату будет на несколько уровней выше, чем из «дедовского»
              аппарата с сухопарником. Для начинающих DS 2 на 6 медных тарелок
              закроет все потребности для начала самогонных экспериментов.
            </p>
            <p>
              Для производства чистого спирта 96% количество ступеней перегонки
              можно увеличить до 18. Царга +12 тарелок обеспечивает значительное
              укрепление дистиллята. Для ещё более высокой крепости (>96% спирт)
              кол-во тарелок можно увеличить до 30, заказав 2 царги (6+12+12
              тарелок). Для этого при оформлении сообщите оператору, что хотите
              заказать 2 царги. Можно заказать товар позже, доставка будет
              бесплатной.
            </p>
            <p>
              В комплекте: колонна 3” (76 мм), кламп 3” и прокладка 3”, доп.
              креплений покупать не нужно.
            </p>
          </div>
          <!--          <div class="im-text" v-else-if="item.variant_id === 2279">
            <p>
              Можно заменить 9 нерж. тарелок на 9, выполненных из меди М1. Медь
              улучшает аромат алкоголя за счет связывания сернистых соединений.
              Соединения серы характерны для зерновой браги (виски) и
              плодово-ягодной браги (бренди). Медь рекомендуется именно для
              перегонки ароматных спиртов.
            </p>
            <p>
              Очистку от сернистых соединений в парах спирта с помощью меди
              проводят как на 1й, так и на 2й перегонке. Именно поэтому тарелок
              много - 9 шт, чтобы контакт спирта с медью был интенсивным.
            </p>
            <p>
              Если Вы начинающий винокур (начинать всегда рекомендуется с
              сахарной браги, как с самой простой), либо предпочитаете перегонку
              только сахарной браги, медные тарелки можно не приобретать либо
              заказать позже, когда опыты со спиртом из сахара закончатся и
              захочется чего-то более изысканного.
            </p>
          </div>-->
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchSelect from "@/pages/Main/components/SwitchSelect";
const m_kol = require("@/pages/Main/assets/modal/m_kol2.jpg");
// const m_kolm = require("@/pages/Main/assets/modal/m_kolm.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  components: { SwitchSelect },
  props: {
    item: {
      default: null,
    },
  },
  data: function () {
    return {
      webp,
      m_kol,
      // m_kolm,
      car,
    };
  },
  methods: {
    getVariant(itemId) {
      let item = this.$store.getters.items.find(
        (v) => parseInt(v.id) === parseInt(itemId)
      );
      let res;
      if (item.variant_id) {
        res = item.variants.find((variant) => variant.id == item.variant_id);
      } else {
        res = item;
      }
      return res;
    },
  },
};
</script>

<style scoped></style>
